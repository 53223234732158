/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotHeader_product$key } from "src/types/__generated__/AuctionLotHeader_product.graphql";
import type { AuctionLotHeader_user$key } from "src/types/__generated__/AuctionLotHeader_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { useNavigate } from "react-router-dom";

import BidNow from "src/app/components/bid-now/BidNow";
import VerifyBidder from "src/app/components/verify-bidder/VerifyBidder";
import { UserContext } from "src/app/context/user";
import { AUCTION_PATH, LOGIN_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBLink,
  SBModal,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

import AuctionLotBidHistoryTable from "./AuctionLotBidHistoryTable";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

enum Modal {
  None,
  Bid,
  Verify,
}

interface AuctionLotUrlOptions {
  auctionLotId: string | undefined;
  imageUrl: string | null;
  title: string;
}

const formatAuctionLotDetailUrl = ({
  auctionLotId,
  imageUrl,
  title,
}: AuctionLotUrlOptions) =>
  auctionLotId == null
    ? "https://shortboxed.com/auctions/"
    : `https://shortboxed.page.link/?link=https://shortboxed.com/auction-lot/${encodeURIComponent(auctionLotId)}&st=${encodeURIComponent(title)}&apn=com.shortboxed&ibi=com.shortboxed&isi=1497091986${imageUrl == null ? "" : `&si=${encodeURIComponent(imageUrl)}`}`;

type Props = Readonly<{
  queryKey: AuctionLotHeader_product$key;
  queryKeyUser: AuctionLotHeader_user$key | null | undefined;
}>;

const AuctionLotHeader = ({
  queryKey,
  queryKeyUser,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();
  const userContext = useContext(UserContext);

  const navigate = useNavigate();

  const [modal, setModal] = useState(Modal.None);
  const [isBidHistoryModalOpen, setIsBidHistoryModalOpen] = useState(false);

  const userData = useFragment(
    graphql`
      fragment AuctionLotHeader_user on User {
        auctionBidStatus
      }
    `,
    queryKeyUser,
  );

  const data = useFragment(
    graphql`
      fragment AuctionLotHeader_product on Product {
        id
        comicDetails {
          title
          number
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
        auctionLot {
          ...AuctionLotBidHistoryTableQuery_auctionLot
          id
          isClosed
          maximumBidAmount
          currentBidAmount
          currentBidCurrency
          startingAmount
          bidCount
          isViewerBidding
          isViewerHighBidder
          closesAt
          bidCount
          uniqueBiddersCount
          bidCount
          auction {
            biddingStartsAt
            lastLotClosesAt
            title
            id
          }
        }
        fmvScore {
          minValue
          maxValue
          currency
          rank
        }
      }
    `,
    queryKey,
  );

  const auctionBidStatus = userData?.auctionBidStatus ?? "INCOMPLETE";

  const { comicDetails, images, auctionLot, fmvScore } = data;
  const { title, number } = comicDetails ?? {};
  const {
    auction,
    bidCount,
    currentBidAmount,
    currentBidCurrency,
    startingAmount,
    id: auctionLotId,
    isClosed,
    isViewerBidding,
    isViewerHighBidder,
    maximumBidAmount,
    closesAt: lotClosesAt,
    uniqueBiddersCount,
  } = auctionLot ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrl = imageEdges.map(({ node }) => node.url)[0];

  const fmvMinValue = fmvScore?.minValue ?? 0;
  const fmvMaxValue = fmvScore?.maxValue ?? 0;
  const fmvCurrency = fmvScore?.currency ?? "USD";

  const startingTimestamp =
    auction?.biddingStartsAt == null
      ? Date.now()
      : Number.parseInt(auction.biddingStartsAt, 10);
  const closingTimestamp =
    auction?.lastLotClosesAt == null
      ? Date.now()
      : Number.parseInt(auction.lastLotClosesAt, 10);

  const auctionId = auction?.id;
  const auctionTitle = auction?.title;
  const auctionDestination = AUCTION_PATH.replace(":id", auctionId ?? "");

  const lotClosesAtTimestamp =
    lotClosesAt == null ? Date.now() : Number.parseInt(lotClosesAt, 10);

  const isPreview =
    auction?.biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(auction.biddingStartsAt, 10) <= 0;

  const { color, expiresIn } = useCountdownTimer(lotClosesAtTimestamp);

  const handleBidHistoryClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsBidHistoryModalOpen(true);
  };

  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const _handleClickViewInApp = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.open(
      formatAuctionLotDetailUrl({
        auctionLotId,
        imageUrl,
        title: productTitle,
      }),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const handleClickBidNow = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (auctionBidStatus === "APPROVED") {
      setModal(Modal.Bid);
    } else {
      setModal(Modal.Verify);
    }
  };

  const productTitle = formatTitle({ number, t, title });

  const shortDateFormatter = useMemo(
    () =>
      isPreview
        ? new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          })
        : new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            month: "numeric",
            year: "numeric",
          }),
    [i18n.languages, isPreview],
  );

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  let subtitle = isPreview
    ? t("auctions.tile.subtitle.preview", {
        timestamp: shortDateFormatter.format(startingTimestamp),
      })
    : t("auctions.tile.subtitle.open-lots", {
        timestamp: shortDateFormatter.format(closingTimestamp),
      });
  if (!isPreview) {
    subtitle = t(
      isClosed
        ? "auctions.tile.subtitle.closed"
        : "auctions.tile.subtitle.open",
      {
        timestamp: shortDateFormatter.format(
          Number.parseInt(lotClosesAt ?? "", 10),
        ),
      },
    );
  }

  const handleClickLogin = (event: React.SyntheticEvent) => {
    event.preventDefault();
    navigate(LOGIN_PATH);
  };

  const shouldShowBidDetails = userContext?.user != null;

  if (!shouldShowBidDetails) {
    return (
      <>
        <SBHeading level={HeadingLevel.H1} style={styles.productTitle}>
          {productTitle}
        </SBHeading>
        <div {...stylex.props(auto, styles.subProductTitleContainer)}>
          <SBParagraph style={styles.subProductTitle}>
            {t("auction.lot.auction-event-title")}
          </SBParagraph>
          <SBLink style={styles.subProductTitle} to={auctionDestination}>
            {t("auction.lot.auction-title", { title: auctionTitle })}
          </SBLink>
        </div>
        <div {...stylex.props(auto, styles.appContainer)}>
          <div {...stylex.props(styles.appLeft)}>
            {currentBidAmount != null && currentBidCurrency != null && (
              <>
                <SBParagraph style={styles.askingPrice}>
                  {currentBidAmount > 0
                    ? t(
                        `auction.lot.actions.${isClosed ? "winning" : "current"}-bid`,
                      )
                    : t("auction.lot.actions.starting-bid")}
                </SBParagraph>
                <SBParagraph style={styles.currentBid}>
                  {currentBidAmount > 0
                    ? formatMoney(
                        currentBidAmount,
                        currentBidCurrency,
                        i18n.language,
                      )
                    : formatMoney(
                        startingAmount ?? 0,
                        currentBidCurrency,
                        i18n.language,
                      )}
                </SBParagraph>
              </>
            )}
            {fmvMinValue > 0 && fmvMaxValue > 0 && (
              <SBParagraph style={styles.fmvRange}>
                {t("product.fmv-range", {
                  max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                  min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
                })}
              </SBParagraph>
            )}
          </div>
          <div {...stylex.props(styles.appRight)}>
            <SBButton
              block={true}
              title={t("auction.lot.buttons.login")}
              type={ButtonType.Button}
              variation={ButtonVariation.Default}
              onClick={handleClickLogin}
            />
          </div>
        </div>
      </>
    );
  }

  const isViewerBiddingView = isClosed ? (
    isViewerHighBidder ? (
      <>
        <div>{t("auction.lot.status.won")} </div>{" "}
        <div {...stylex.props(auto, styles.wonSubtext)}>
          {t("auction.lot.status.won-subtext")}
        </div>
      </>
    ) : (
      t("auction.lot.status.lost")
    )
  ) : isViewerHighBidder ? (
    t("auction.lot.status.winning")
  ) : (
    t("auction.lot.status.outbid")
  );

  let button: React.ReactNode = (
    <SBButton
      block={true}
      style={styles.button}
      title={
        isViewerBidding
          ? t("auction.lot.buttons.increase-bid")
          : t("auction.lot.buttons.bid-now")
      }
      type={ButtonType.Button}
      variation={ButtonVariation.Emphasis}
      onClick={handleClickBidNow}
    />
  );

  if (isPreview) {
    button = null;
  }

  return (
    <>
      <SBHeading level={HeadingLevel.H1} style={styles.productTitle}>
        {productTitle}
      </SBHeading>
      <div {...stylex.props(auto, styles.subProductTitleContainer)}>
        <SBParagraph style={styles.subProductTitle}>
          {t("auction.lot.auction-event-title")}
        </SBParagraph>
        <SBLink style={styles.subProductTitle} to={auctionDestination}>
          {t("auction.lot.auction-title", { title: auctionTitle })}
        </SBLink>
      </div>
      <div {...stylex.props(auto, styles.container)}>
        <div {...stylex.props(auto, styles.columns)}>
          <div {...stylex.props(styles.columnLeft)}>
            {currentBidAmount != null && currentBidCurrency != null && (
              <>
                <SBParagraph style={styles.askingPrice}>
                  {currentBidAmount > 0
                    ? t(
                        `auction.lot.actions.${isClosed ? "winning" : "current"}-bid`,
                      )
                    : t("auction.lot.actions.starting-bid")}
                </SBParagraph>
                <SBParagraph style={styles.currentBid}>
                  {currentBidAmount > 0
                    ? formatMoney(
                        currentBidAmount,
                        currentBidCurrency,
                        i18n.language,
                      )
                    : formatMoney(
                        startingAmount ?? 0,
                        currentBidCurrency,
                        i18n.language,
                      )}
                </SBParagraph>
              </>
            )}
            {fmvMinValue > 0 && fmvMaxValue > 0 && (
              <SBParagraph style={styles.fmvRange}>
                {t("product.fmv-range", {
                  max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                  min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
                })}
              </SBParagraph>
            )}
          </div>
          <div {...stylex.props(styles.columnRight)}>
            <SBParagraph style={styles.subtitle}>{subtitle}</SBParagraph>
            <div {...stylex.props(styles.bidInfo)}>
              {!isClosed && (
                <SBParagraph style={styles.expiresIn(color)}>
                  {expiresIn}
                </SBParagraph>
              )}
              <SBLink onClick={handleBidHistoryClick}>
                <SBParagraph style={styles.bidCount}>
                  {t("auction.lot.bid-count", {
                    bidCount: numberFormatter.format(bidCount ?? 0),
                  })}
                </SBParagraph>
              </SBLink>
            </div>
          </div>
        </div>
        {isViewerBidding ? (
          <SBParagraph
            style={[
              styles.status,
              isViewerHighBidder && styles.highestBid,
              !isViewerHighBidder && styles.statusOutbid,
            ]}
          >
            {isViewerBiddingView}
          </SBParagraph>
        ) : null}
        {!isClosed && userContext.user != null && (
          <>
            {button}
            {isViewerBidding &&
            maximumBidAmount != null &&
            currentBidCurrency != null ? (
              <SBParagraph style={styles.maxBid}>
                {t("auction.lot.status.current-max-bid", {
                  price: formatMoney(
                    maximumBidAmount,
                    currentBidCurrency,
                    i18n.language,
                  ),
                })}
              </SBParagraph>
            ) : null}
          </>
        )}
      </div>
      <VerifyBidder
        auctionLotId={auctionLotId}
        isOpen={modal === Modal.Verify}
        setOpen={handleCloseModal}
      />
      <BidNow
        auctionLotId={auctionLotId}
        isOpen={modal === Modal.Bid}
        setOpen={handleCloseModal}
      />
      <SBModal
        headerText={t("auction.lot.bid-history.modal-header")}
        isOpen={isBidHistoryModalOpen}
        setOpen={setIsBidHistoryModalOpen}
      >
        <div {...stylex.props(styles.bidsModal)}>
          {auctionLot ? (
            <>
              <div {...stylex.props(styles.detailsContainer)}>
                <div>
                  <div {...stylex.props(styles.detailsRow)}>
                    <SBParagraph style={styles.details}>
                      {t("auction.lot.bid-history.closes-at")}
                    </SBParagraph>
                    <SBParagraph style={styles.details}>
                      {shortDateFormatter.format(
                        Number.parseInt(lotClosesAt ?? "", 10),
                      )}
                    </SBParagraph>
                  </div>
                  <div {...stylex.props(styles.detailsRow)}>
                    <SBParagraph style={styles.details}>
                      {t("auction.lot.bid-history.total-bids")}
                    </SBParagraph>
                    <SBParagraph style={styles.details}>
                      {numberFormatter.format(bidCount ?? 0)}
                    </SBParagraph>
                  </div>
                  <div {...stylex.props(styles.detailsRow)}>
                    <SBParagraph style={styles.details}>
                      {t("auction.lot.bid-history.unique-bidders")}
                    </SBParagraph>
                    <SBParagraph style={styles.details}>
                      {numberFormatter.format(uniqueBiddersCount ?? 0)}
                    </SBParagraph>
                  </div>
                </div>
              </div>
              <div {...stylex.props(styles.bidHistory)}>
                <AuctionLotBidHistoryTable
                  isClosed={isClosed}
                  queryKey={auctionLot}
                />
              </div>
            </>
          ) : null}
        </div>
      </SBModal>
    </>
  );
};

const styles = stylex.create({
  appContainer: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginBlock: 24,
    padding: 16,
  },
  appLeft: {
    alignSelf: "flex-start",
  },
  appRight: {
    alignSelf: "flex-end",
  },
  askingPrice: {
    fontSize: 12,
    margin: 0,
  },
  bidCount: {
    fontSize: 12,
    margin: 0,
    textAlign: "right",
  },
  bidHistory: {
    maxHeight: 300,
    overflowY: "auto",
  },
  bidHistorySummaryData: {
    fontWeight: 600,
    gridColumn: "span 2 / span 2",
  },
  bidHistorySummaryRow: {
    display: "grid",
    gridTemplateColumns: {
      default: "repeat(3, minmax(0, 1fr));",
    },
    marginTop: 4,
  },
  bidInfo: {
    alignItems: "flex-end",
    alignSelf: "flex-end",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 16,
  },
  bidsModal: {
    margin: 10,
  },
  button: {
    marginTop: 16,
  },
  columnLeft: {
    marginRight: 16,
  },
  columnRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  columns: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    marginBlock: 24,
    padding: 16,
  },
  currentBid: {
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 48,
    lineHeight: 0.875,
    marginBlock: 8,
    textTransform: "uppercase",
  },
  details: {
    fontWeight: 400,
  },
  detailsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 16,
    width: "100%",
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  expiresIn: (color: string) => ({
    color,
    fontSize: 12,
    margin: 0,
    textAlign: "right",
  }),
  fmvRange: {
    fontSize: 12,
    margin: 0,
  },
  highestBid: {
    color: colors.auctionWinningColor,
  },
  maxBid: {
    fontSize: 12,
    marginBottom: 0,
    marginTop: 8,
    textAlign: "center",
  },
  productTitle: {
    fontSize: {
      [MOBILE]: 48,
      [TABLET]: 72,
      default: 96,
    },
    marginBottom: 8,
  },
  status: {
    marginTop: 8,
    textAlign: "center",
  },
  statusOutbid: {
    color: colors.colorEmphasis,
  },
  subProductTitle: {
    color: colors.colorMuted,
    fontSize: 14,
    textUnderlineOffset: "4px",
  },
  subProductTitleContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 4,
    justifyContent: "flex-start",
  },
  subtitle: {
    fontSize: 12,
    margin: 0,
    textAlign: "right",
  },
  wonSubtext: {
    color: colors.colorMuted,
    fontSize: 14,
  },
});

export default AuctionLotHeader;
